import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter, GLGlobal, GLUtil, connect } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { GSAdminLocale } from '@app/locales/localeid';
import { GSAdminAction, SessionStorageKeys, fmtMsg } from '@app/util';
import { StateType } from '@app/states';
import { getGlobalProducts } from '@app/states/admin/product';
import { GlobalProductModelProps } from '@app/service/admin/products';
import { ListingPage } from './components/listing';

@withRouter
@connect(({ product: { globalList: list, globalTotal: total, globalActiveCnt: activeCnt }, 
            addProductAction = GSAdminAction.CreateGlobalProduct,
            addProductUri = PathConfig.GlobalProductsCreate,
            canAdd = true,
            paginationKey = SessionStorageKeys.GlobalProductsPagination,
            listStoreKey = 'globalproduct-list-states',
            listStateKey = 'globalproduct-list-states',
            columns = getColumns() }: StateType) => ({
    list,
    total,
    activeCnt,
    addProductAction,
    addProductUri,
    canAdd,
    paginationKey,
    listStoreKey,
    listStateKey,
    columns
}), {
        getData: getGlobalProducts
    })
export class GlobalProductPage extends ListingPage {

    static defaultProps = {
        pageTitle: GSAdminLocale.GlobalProductListTitle,
        defaultSorter: { 
            columnName: GlobalProductModelProps.name, 
            columnSortName: GlobalProductModelProps.name, 
            ascending: true 
        },
        columnSortingMap: new Map<string, string>([
            [ GlobalProductModelProps.versionGroupName, 'versionGroupId' ]
        ])
    };

}

function getColumns() {
    return [
        {
            title: GSAdminLocale.ProductName,
            dataIndex: GlobalProductModelProps.name,
            width: '50%',
            render: (text, record, index) => {
                if (GLGlobal.isActionValid(GSAdminAction.EditGlobalProduct)) {
                    return `<a href="${GLUtil.pathStringify(PathConfig.GlobalProductsEdit , { globalProductId: record.id })}" > <span>${text}</span> </a>`
                }
                else {
                    return `<span>${text}</span>`;
                }
            },
            className:'navigation-col'
        },
        // {
        //     title: GSAdminLocale.GlobalProductContentVersion,
        //     dataIndex: GlobalProductModelProps.versionGroupName,
        //     width: '20%'
        // },
        {
            title: GSAdminLocale.ProductUnit,
            dataIndex: GlobalProductModelProps.unit,
            width: '30%'
        }
    ];
}